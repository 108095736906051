import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class AlertService {

  errorEmitter: EventEmitter<any> = new EventEmitter();

  constructor() {}

  emitErrorMessage(message: any) {
    this.errorEmitter.emit(message);
  }

  getErrorEmitter() {
    return this.errorEmitter;
  }
}
