import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppListComponent } from './app-list/app-list.component';
import { AppEditComponent } from './app-edit/app-edit.component';

import { AppListResolver } from './app-list/app-list-resolver.service';
import { MasterVariableAppListResolver } from './../apps/app-list/masterVariable-app-list-resolver.service';

const appsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'new',
        component: AppEditComponent
      },
      {
        path: 'edit/:id',
        component: AppEditComponent,
      },
      {
        path: '',
        component: AppListComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(appsRoutes)],
  exports: [RouterModule],
  providers: [
    AppListResolver,
    MasterVariableAppListResolver,
  ]
})
export class AppsRoutingModule { }
