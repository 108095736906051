import { Component, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { forkJoin, of } from 'rxjs';

import { AlertService } from './../../../alert.service';
import { Organization } from './../organization';
import { Account } from './../../accounts/account';
import { OrganizationService } from './../organization.service';
import { Role } from '../../roles/role';
import { App } from '../../apps/app';
import { AuthService } from '../../../auth.service';
import { TabStore } from '../../shared/store/tab.store';
import { AppService } from '../../apps/app.service';
import { ViewService } from '../../views/views.service';
import { View } from '../../views/view';
import { RoleService } from '../../roles/role.service';
import { catchError, tap } from 'rxjs/operators';

declare var _: any;

@Component({
  selector: 'organization-edit',
  templateUrl: './organization-edit.component.html',
  styleUrls: ['./organization-edit.component.css']
})
export class OrganizationEditComponent implements OnInit {
  errorMessage: string;
  organization: Organization;
  organizations: Organization[];
  roles: Role[];
  initialRoles: Role[];
  apps: App[] = [];
  organizationApps: App[];
  showApps: boolean = false;
  organizationViews: any[];
  viewControl: FormControl = new FormControl();

  @Output() editMode: boolean = false;
  module: string = 'Organization';
  cardHeaderState: string = 'edit';
  initialCardHeaderState: string;
  currentTabIndex: number;
  componentName = this.module;
  url = this._location.path(false).split(/[?#]/)[0];
  tabStateId = {
    url: this.url,
    component: this.componentName
  };

  parent: boolean = false;
  isAdmin: boolean = false;
  isEndUser: boolean = false;
  showCalendar: boolean = false;
  canCreate: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private organizationService: OrganizationService,
    private _location: Location,
    private authService: AuthService,
    public dialog: MatDialog,
    private tabStore: TabStore,
    private appsService: AppService,
    private viewService: ViewService,
    private roleService: RoleService,
  ) {}

  async ngOnInit() {
    const id = this.route.snapshot.params['id'];
    const { account } = await this.loadInitialData();

    this.checkAccountPermissions(account);

    if (id) {
      this.organization = await this.organizationService.getOrganization(id).toPromise();
      this.showCalendar = this.organization.idOrganization == account.idOrganization || this.organization.hierarchy.includes('/-' + account.idOrganization + '-/');
      if (this.showCalendar) {
        this.initSelectedTab();
      }
      this.loadData(account, this.organization);
    } else {
      if (account['role'].weight > 2) {
        this.router.navigate(['/']);
      }
      this.initializeNewData();
    }

    if (this.showApps) {
      this.getAppsAndViews();
      this.getOrgApps(this.organization.idOrganization ? this.organization.idOrganization.toString() : null);
      this.getOrgViews(this.organization.idOrganization);
    }
  }

  private loadData(account: Account, organization: Organization): void {
    if (account.idOrganization == organization.idOrganization) {
      this.parent = true;
    }
    let hierarchy = organization.hierarchy;
    let newHierarchy;
    if (organization.idOrganization == 1) {
      newHierarchy = hierarchy;
    } else {
      let orgStr = "-" + organization.idOrganization.toString() + "-/";
      let indexof = hierarchy.lastIndexOf(orgStr);
      newHierarchy = hierarchy.slice(0, indexof);
    }
    organization.hierarchy = newHierarchy;
    this.organization = organization;

    this.organizations = this.organizations['rows'].filter((obj) => {
      return obj.idOrganization !== organization.idOrganization;
    });
  }

  private initializeNewData(): void {
    this.organization = new Organization(null, null, '', '', null, '', null);
    this.editMode = true;
    this.cardHeaderState = 'new';
    this.initialCardHeaderState = 'onlyEdit';
  }

  private async loadInitialData() {
    return forkJoin({
      account: this.authService.getAccount().pipe(
              tap((account) => {
                return account;
              }),
              catchError(error => {
                console.error('Error in getAccount:', error);
                this.alertService.emitErrorMessage({text: 'Error getting account', type: 'danger'});
                this._location.back();
                return of({ error: true, message: 'Error getting account' });
              })
            ),
      roles: this.roleService.getRoles(null, 0, 'weight', 'asc', '').pipe(
        tap((roles) => { 
          this.roles = roles['rows'];
          this.initialRoles = this.roles;
        }),
        catchError(error => {
          console.error('Error in getRoles:', error);
          this.alertService.emitErrorMessage({text: 'Error getting roles', type: 'danger'});
          this._location.back();
          return of({ error: true, message: 'Error getting roles' });
        })
      ),
      organizations: this.organizationService.getOrganizations(null, 0, 'name', 'asc', '').pipe(
        tap((organizations) => {
          this.organizations = organizations['rows'];
        }),
        catchError(error => {
          console.error('Error in getOrganizations:', error);
          this.alertService.emitErrorMessage({text: 'Error getting organizations', type: 'danger'});
          this._location.back();
          return of({ error: true, message: 'Error getting organizations' });
        })
      ),
    }).toPromise();
  }

  private checkAccountPermissions(account: Account): void {
    switch (account.idRole) {
      case 1:
        this.isAdmin = true;
        this.showApps = true;
        break;
      case 2:
        break;
      case 3:
        this.isEndUser = true;
        this.canCreate = false;
        this.cardHeaderState = 'disabled';
        break;
      default:
        break;
    }
  }

  private getAppsAndViews(): void {
    console.log('getAppsAndViews');
    this.appsService.getApps(null, null, 'name', 'ASC', null).subscribe((res: any) => {
      this.apps = res['rows'];
      console.log('this.apps', this.apps);
      if (this.organization.idRole == null) {
        this.selectAllViews();
      }
    });
  }

  private getOrgApps(idOrganization: string): void {
    if (idOrganization == null) return;
    this.appsService.getOrganizationApps(idOrganization.toString()).subscribe((apps: App[]) => {
      this.organizationApps = apps;
    });
  }

  private selectAllViews(): void {
    this.organizationViews = [];
    this.apps.forEach((app) => {
      Array.prototype.push.apply(this.organizationViews, app["views"]);
    });
    this.viewControl.setValue(this.organizationViews);
  }

  private getOrgViews(idOrganization: number): void {
    if (idOrganization == null) return;
    this.viewService.getOrganizationViews(idOrganization).subscribe((views: View[]) => {
      this.organizationViews = views;
      this.viewControl.setValue(this.organizationViews);
    });
  }

  private initSelectedTab(): void {
    this.currentTabIndex = this.tabStore.getTabIndex(this.tabStateId);
    if (!this.currentTabIndex) {
      this.currentTabIndex = 0;
      this.tabStore.addTab(this.tabStateId, this.currentTabIndex);
    }
  }

  public onTabChange(event): void {
    this.tabStore.setTabIndex(this.tabStateId, event.index);
  }

  onheaderActionEmitter(message: any): void {
    switch (message.text) {
      case 'edit':
        this.editMode = !this.editMode;
        break;
      case 'save':
        this.onSave(this.organization);
        this.editMode = !this.editMode;
        break;
      case 'cancel':
        this.editMode = !this.editMode;
        break;
      case 'remove':
        this.onDelete();
        break;
      case 'back':
        this.onCancel();
        break;
    }
  }

  onChangeOrg(event: any, organization: any) {
    if (event.isUserInput) {
      this.roles = _.filter(this.initialRoles, function (o) {
        return o.weight >= organization.role.weight;
      });
      if (this.cardHeaderState == 'new' && this.isAdmin) {
        this.getOrgApps(organization.idOrganization.toString());
      }
    }
  }

  compareApps(c1: App, c2: App): boolean {
    return c1 && c2 ? c1.idApp === c2.idApp : c1 === c2;
  }

  compareViews(c1: View, c2: View): boolean {
    return c1 && c2 ? c1.idView === c2.idView : c1 === c2;
  }

  onFileChanged(event) {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      if (file.size < 50000) {
        let fr = new FileReader();
        fr.onload = (event: any) => {
          let base64 = event.target.result;
          this.organization.image = base64;
        };
        fr.readAsDataURL(file);
      } else {
        this.alertService.emitErrorMessage({ text: 'File too big', type: 'error' });
      }
    }
  }

  onCancel(): void {
    this._location.back();
  }

  onSave(organization: Organization): void {
    if (this.organizationApps) {
      this.organization["apps"] = this.organizationApps;
    } else {
      this.organization["apps"] = [];
    }

    if (this.organizationViews) {
      this.organization["views"] = this.organizationViews;
    } else {
      this.organization["views"] = [];
    }

    if (!this.organization.name) {
      this.alertService.emitErrorMessage({ text: 'Name is required. Please fill out the field and resubmit the form', type: 'danger' });
      this.editMode = !this.editMode;
    } else if (!this.organization.hierarchy) {
      this.alertService.emitErrorMessage({ text: 'Parent organization is required. Please fill out the field and resubmit the form', type: 'danger' });
      this.editMode = !this.editMode;
    } else if (!this.organization.idRole && this.isAdmin) {
      this.alertService.emitErrorMessage({ text: 'Role is required. Please fill out the field and resubmit the form', type: 'danger' });
      this.editMode = !this.editMode;
    } else {
      this.organizationService.saveOrganization(this.organization).subscribe(
        response => {
          this._location.back();
        },
        error => {
          this.alertService.emitErrorMessage({ text: error.error, type: 'danger' });
          this.editMode = !this.editMode;
          this.errorMessage = <any>error;
        }
      );
    }
  }

  onDelete(): void {
    this.organizationService.removeOrganization(this.organization.idOrganization).subscribe(
      response => {
        this._location.back();
      },
      error => {
        this.alertService.emitErrorMessage({ text: error.error, type: 'danger' });
        this.errorMessage = <any>error;
      }
    );
  }
}
