import { Injectable } from '@angular/core';

import { Store } from "./store";
import { TabState } from './tab-state';

@Injectable({
  providedIn: 'root'
})
export class TabStore extends Store<TabState> {

  constructor() {
    super(new TabState());
  }

  /**
   * Create a new tab
   * @param id url and component
   * @param index tab index
   * @param textLabel tab label
   */
  addTab(id: { url: string, component: string}, index: number, textLabel: string = null ) {
    this.setState({
      ...this.state,
      tabs: [
        ...this.state.tabs,
        {
          id,
          index,
          textLabel
        }
      ]
    });
  }
  
  /**
   * Get (first) tab index by id
   * @param id url and component
   */
  getTabIndex(id: { url: string, component: string }){
    let index = null;
    this.state.tabs.some((tab) => {
        return (tab.id.url == id.url && tab.id.component == id.component ) ? ((index = tab.index), true) : false;
    });
    return index;
  }

  /**
   * Set index to a tab with given id
   * @param id url and component
   * @param index new index
   */
  setTabIndex(id: { url: string, component: string }, index: number){
    this.setState({
      ...this.state,
      tabs: this.state.tabs.map(tab => {
        if (tab.id.url == id.url && tab.id.component == id.component ) {
          return {
            ...tab, 
            index
          }
        }
        return tab;
      })
    })
  }

  /**
   * Get (first) tab index by id
   * @param id url and component
   */
  getTabTextLabel(id: { url: string, component: string }){
    let index = null;
    let textLabel = null;
    this.state.tabs.some((tab) => {
        return (tab.id.url == id.url && tab.id.component == id.component ) ? ((index = tab.index), (textLabel = tab.textLabel), true) : false;
    });
    return {index, textLabel};
  }

  /**
   * Set index to a tab with given id
   * @param id url and component
   * @param index new index
   */
  setTabTextLabel(id: { url: string, component: string }, index: number, textLabel: string){
    this.setState({
      ...this.state,
      tabs: this.state.tabs.map(tab => {
        if (tab.id.url == id.url && tab.id.component == id.component ) {
          return {
            ...tab, 
            index,
            textLabel
          }
        }
        return tab;
      })
    })
  }

  /**
   * Delete (first) tab ocurrence by id
   * @param id url and component
   */
  deleteTab(id: { url: string, component: string }){
    this.state.tabs.some((tab, i) => {
        return (tab.id.url == id.url && tab.id.component == id.component) ? ((this.state.tabs.splice(i, 1)), true) : false;
    });
  }

  /**
   * Delete all tabs
   */
  deleteAllTabs(){
    this.setState(new TabState);
  }

}