<div class="container" [class.is-mobile]="mobileQuery.matches">

  <mat-toolbar class="toolbar">
    <mat-toolbar-row *ngIf="impersonating" class="red-toolbar-row">
        <app-impersonate></app-impersonate>
    </mat-toolbar-row>
    <mat-toolbar-row class="toolbar-row" [style.background-color]="headerColor">
      <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
      <h1 class="app-name">Vixion Manager</h1>
      <span *ngIf="showEnvironment">&nbsp; - {{host}}</span>
      <span class="spacer"></span>
      <app-header></app-header>
    </mat-toolbar-row>  
  </mat-toolbar>


  <mat-sidenav-container class="sidenav-container"
                         [style.marginTop.px]="mobileQuery.matches ? (impersonating ? 82 : 56) : 0">
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" 
                (openedChange)="onSidenavChange($event)"
                [opened]="isSidenavOpened"
                 [fixedInViewport]="mobileQuery.matches" [fixedTopGap]="impersonating ? 82 : 56">
      <mat-nav-list>
        <app-sidebar></app-sidebar>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
      <app-loading></app-loading>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
