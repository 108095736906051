import { Component,
         OnInit,
         Output}                                   from '@angular/core';
import { Router, ActivatedRoute }           from '@angular/router';
import { Location }                                 from '@angular/common';

import { Role }          from './../role';
import { RoleService }   from './../role.service';
import { AuthService } from '../../../auth.service';
import { forkJoin, from, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AlertService } from '../../../alert.service';

@Component({
  selector: 'role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.css']
})
export class RoleEditComponent implements OnInit {

  errorMessage: string;
  role: Role;
  module: string = 'Roles';
  @Output() editMode: boolean = false;
  cardHeaderState: string = 'edit';
  initialCardHeaderState = 'edit';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private roleService: RoleService,
    private _location: Location,
    private authService: AuthService,
    private alertService: AlertService,
  ) {}

  async ngOnInit() {
    const id = this.route.snapshot.params['id'];
    await this.loadInitialData(id);
    if (!id) {
      this.role = new Role(null, null,'',null,null);
      this.editMode = true;
      this.cardHeaderState = 'new';
      this.initialCardHeaderState = 'onlyEdit';
    }
  }

  private async loadInitialData(id: string) {
      return forkJoin({
        idRole: from(this.authService.getRole()).pipe(
          tap((idRole) => { 
            if ( idRole != 1) {
              this.router.navigate(['/']);
            }
          }),
          catchError(error => {
            console.error('Error in idRole getRole:', error);
            this.alertService.emitErrorMessage({text: 'Error getting idRole', type: 'danger'});
            this._location.back();
            return of({ error: true, message: 'Error getting idRole' });
          })
        ),
        role: this.roleService.getRole(id).pipe(
          tap((role) => { 
            this.role = role;
          }),
          catchError(error => {
            console.error('Error in getRole:', error);
            this.alertService.emitErrorMessage({text: 'Error getting role', type: 'danger'});
            this._location.back();
            return of({ error: true, message: 'Error getting role' });
          })
        )
      }).toPromise();
    }

  onheaderActionEmitter(message:any):void {
    switch (message.text) {
      case 'edit':
        this.editMode = !this.editMode;
        break;
      case 'save':
        this.onSave();
        // this.onSelect(message.data);
        this.editMode = !this.editMode;
        break;
      case 'cancel':
        // this.onEdit(message.data);
        this.editMode = !this.editMode;
        break;
      case 'remove':
        console.log('before remove')
        this.onDelete();
        console.log('after remove')
        // this.onRemove(message.data);
        break;
      case 'back':
        this.onCancel();
        break;
    }
  }

  onCancel(): void {
    this._location.back();
  }

  onSave(): void {
    this.roleService.saveRole(this.role)
        .subscribe(
          response => { this._location.back() },
          error =>  this.errorMessage = <any>error)
    this.editMode = !this.editMode;
  }

  onDelete(): void {
    this.roleService.removeRole(this.role.idRole).subscribe(
                          response => { this._location.back() },
                          error =>  this.errorMessage = <any>error)
    this.editMode = !this.editMode;
  }

}
