import {
  Component,
  OnInit,
  Output
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { AlertService } from './../../../alert.service';
import { App } from './../app';
import { AppService } from './../app.service';

import { AuthService } from '../../../auth.service';
import { forkJoin, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'app-edit',
  templateUrl: './app-edit.component.html',
  styleUrls: ['./app-edit.component.css']
})
export class AppEditComponent implements OnInit {

  errorMessage: string;
  app: App;
  isAdmin: boolean = false;
  @Output() editMode: boolean = false;
  module: string = 'App';
  cardHeaderState: string = 'edit';
  alias: string;
  url: string;

  id: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private _location: Location,
    private alertService: AlertService,
    private authService: AuthService
  ) { }

  async ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    await this.loadInitialData();

    if (this.id) {
      this.loadApp();
    } else {
      this.app = new App(null, null, null, null, null, null, null);
      this.editMode = true;
      this.cardHeaderState = 'new';
    }
  }

  private async loadInitialData() {
    return forkJoin({
      account: this.authService.getAccount().pipe(
        tap((account) => {
          if (account['role'].weight == 1) {
            this.isAdmin = true
          }
          else {
            this.router.navigate(['/']);
          }
        }),
        catchError(error => {
          console.error('Error in getAccount:', error);
          this.alertService.emitErrorMessage({ text: 'Error getting account', type: 'danger' });
          this._location.back();
          return of({ error: true, message: 'Error getting account' });
        })
      )
    }).toPromise();
  }

  private async loadApp() {
    this.appService.getApp(this.id).pipe(
      tap((app) => {
        this.app = app[0];
        this.url = app[0]['defaultUrl'];
      }),
      catchError(error => {
        console.error('Error in getApp:', error);
        this.alertService.emitErrorMessage({ text: 'Error getting app', type: 'danger' });
        this._location.back();
        return of({ error: true, message: 'Error getting app' });
      })
    ).toPromise();
  }

  onheaderActionEmitter(message: any): void {
    switch (message.text) {
      case 'edit':
        this.editMode = !this.editMode;
        break;
      case 'save':
        this.app["alias"] = this.alias;
        this.app["url"] = this.url;
        this.app["id"] = this.url.includes('custom') ? this.url.substring('custom/'.length) : this.url;
        this.onSave(this.app);
        this.editMode = !this.editMode;
        break;
      case 'cancel':
        this.editMode = !this.editMode;
        break;
      case 'remove':
        this.onDelete();
        break;
      case 'back':
        this.onCancel();
        break;
    }
  }

  onCancel() {
    this._location.back();
  }

  onFileChanged(event) {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      if (file.size < 50000) {
        let fr = new FileReader();
        fr.onload = (event: any) => {
          let base64 = event.target.result
          this.app.image = base64;
        }
        fr.readAsDataURL(file)
      } else {
        this.alertService.emitErrorMessage({ text: 'File too big', type: 'error' });
      }
    }
  }

  onSave(app: App) {
    if (app.name) {
      this.appService.saveApp(app)
        .subscribe(
          response => {
            this._location.back();
          },
          error => {
            this.editMode = !this.editMode;
            this.errorMessage = <any>error
          });
    }
    else {
      this.alertService.emitErrorMessage({ text: 'Name is required. Please fill out the field and resubmit the form', type: 'danger' });
      this.editMode = !this.editMode;
    }

  }

  onDelete(): void {
    this.appService.removeApp(this.app.idApp)
      .subscribe(
        response => {
          this._location.back();
        },
        error => this.errorMessage = <any>error);
  }

}
