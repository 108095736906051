import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from "@angular/core";

@Injectable()
export class CalendarUtils {
    // create events using workShifts weekDay and holidays
    public async generateEvents(viewDate, workShiftsGroup: any[], idOrganization, idEntity) {
      let events = [];
      let editEvent = false;
      const monthDays = this.getDaysInMonth(viewDate.getMonth()+1, viewDate.getFullYear());
      for (let i = 0; i < monthDays.length; i++) {
        const day = monthDays[i];
        const dateFreqFormat = moment(day).format('YYYY-MM-DD');
        
        let workedCalendarsActive = [];
        for (let j = 0; j < workShiftsGroup.length; j++) {
          const workShift = workShiftsGroup[j];
          for (let k = 0; k < workShift.rows.length; k++) {
              const workedCalendars = workShift.rows[k];
              let calendarActive = false;
              workedCalendars.shift.historicalChangesShift.sort((a,b) => moment(a.date, 'YYYY-MM-DD').valueOf() - moment( b.date, 'YYYY-MM-DD').valueOf());
              for (let l = 0; l < workedCalendars.historicalChanges.length; l++) {
                const historical = workedCalendars.historicalChanges[l];
                const isSameOrBefore = moment(historical.date, 'YYYY-MM-DD').isSameOrBefore(dateFreqFormat);
                if (isSameOrBefore) {
                  calendarActive = true;
                  workedCalendars.weekDay = historical.weekDay;
                  workedCalendars.startDayBefore = historical.startDayBefore;
                }
              }
              if (workedCalendars.startDayBefore === null) {
                workedCalendars.shift.historicalChangesShift.sort((a,b) => moment(a.date, 'YYYY-MM-DD').valueOf() - moment( b.date, 'YYYY-MM-DD').valueOf());
                for (let l = 0; l < workedCalendars.shift.historicalChangesShift.length; l++) {
                    const historical = workedCalendars.shift.historicalChangesShift[l];
                    const isSameOrBefore = moment(historical.date, 'YYYY-MM-DD').isSameOrBefore(dateFreqFormat);
                    if (isSameOrBefore) {
                      workedCalendars.shift.startDayBefore = historical.startDayBefore;
                    }
                }
                workedCalendars.startDayBefore = workedCalendars.shift.startDayBefore;
              }
  
              if (calendarActive) workedCalendarsActive.push(workedCalendars);
  
          }
  
          if (workedCalendarsActive.length === 0 && workShift.rows[0].historicalChanges.length === 0) {
              workedCalendarsActive = workedCalendarsActive.concat(workShift.rows);
          }
  
          if (workedCalendarsActive.length > 0) {
              break;
          }
        }
        for (let j = 0; j < workedCalendarsActive.length; j++) {
          const workShiftActive = workedCalendarsActive[j];
          const date = dateFreqFormat;
          const shift = workShiftActive.shift;
  
          const dayMoment = moment(date, 'YYYY-MM-DD');
          const dateStart = moment(date + ' ' + shift.start, 'YYYY-MM-DD HH:mm:ss');
          let start = new Date(dateStart.toDate());
          
          const dateEnd = moment(date + ' ' + shift.end, 'YYYY-MM-DD HH:mm:ss');
          let end = new Date(dateEnd.toDate());
  
          const weekDay = dayMoment.day() === 0 ? 6 : dayMoment.day() -1;
          
          const weekDayFormat = (workShiftActive.weekDay).split("").map(d => d === "1" ? true : false);
          const isWorkedDay = weekDayFormat ? weekDayFormat[weekDay] : true;
          if (!editEvent) {
            if (idOrganization) {
              if (workShiftActive) editEvent = workShiftActive.idOrganization === idOrganization;
            } else {
              if (workShiftActive) editEvent = workShiftActive.idEntity === idEntity;
            }
          }
          if (isWorkedDay) {
            let worked: boolean = false;
            if (workShiftActive) {
              if (workShiftActive.workedCalendarFreeShift) {
                const index = _.findIndex(workShiftActive.workedCalendarFreeShift, ['date', date]);
                worked = index > -1 ? false : true
              }           
            }
            const timestampStart = await this.calcTimestamStartShift(workShiftActive, date);
            events.push({
              start,
              end,
              timestampStart,
              title: shift.name,
              color: {
                primary: shift.color,
                secondary: shift.color,
              },
              worked: worked,
              date: moment(date, 'YYYYMMDD').format('YYYY-MM-DD'),
              idShift: shift.idShift,
              editEvent: editEvent,
              meta: workShiftActive,
              espacialDay: false
            });
          } else {
            if (workShiftActive.specialDay) {
              const index = _.findIndex(workShiftActive.specialDay, ['date', date]);
              if (index > -1) {
                if (idOrganization) {
                  if (workShiftActive.specialDay && workShiftActive.specialDay.length > 0) editEvent = workShiftActive.specialDay[index].idOrganization === idOrganization;
                } else {
                  if (workShiftActive.specialDay && workShiftActive.specialDay.length > 0) editEvent = workShiftActive.specialDay[index].idEntity === idEntity;
                }
                const timestampStart = await this.calcTimestamStartShift(workShiftActive, date);
                events.push({
                  start,
                  end,
                  timestampStart,
                  title: shift.name,
                  color: {
                    primary: shift.color,
                    secondary: shift.color,
                  },
                  worked: true,
                  date: moment(date, 'YYYYMMDD').format('YYYY-MM-DD'),
                  idShift: shift.idShift,
                  editEvent: editEvent,
                  meta: workShiftActive,
                  espacialDay: true
                });
  
              }
            }
          }
        };
        
      }
      const eventsSort = events.sort((a, b) => a.timestampStart - b.timestampStart);
      return eventsSort;
    }

    // get array of dates of the selected month-year
    private getDaysInMonth (month, year): Date[] {
      return (new Array(31)).fill('').map((v,i)=>new Date(year,month-1,i+1)).filter(v=>v.getMonth()===month-1)
    }

    private async calcTimestamStartShift(workedCalendar, date) {
      const start = moment(date + ' ' + workedCalendar.shift.start, 'YYYY-MM-DD HH:mm:ss');
      const end = moment(date + ' ' + workedCalendar.shift.end, 'YYYY-MM-DD HH:mm:ss');
      if (workedCalendar.shift.start === '00:00:00' && workedCalendar.shift.end === '00:00:00') {
        end.add(1, 'days');
      } else if (workedCalendar.startDayBefore && start.isSameOrAfter(end)) {
        start.subtract(1, 'days');
      } else if (!workedCalendar.startDayBefore && start.isSameOrAfter(end)) {
        end.add(1, 'days');
      } 
      return start.unix();
    }
}